import React from 'react'

import { connect } from 'react-redux'
import { actions as formActions } from 'react-redux-form'
import get from 'react-redux-form/utils/get'
import { Segment } from "semantic-ui-react";
import RichTextEditor from "../richText/RichTextEditor";
import { DynamicRichTextRender } from "../richText/RichTextRender";

class SemanticRichTextWidget extends React.Component {
  render() {
    const { mentionOptions, mentionExamples } = this.props.options
    return (
      <div>
        <RichTextEditor serializeToHtml={this.props.serializeToHtml}
                        onChange={this.props.handleChange}
                        value={this.props.value}
                        mentionOptions={mentionOptions}
        />
        {mentionOptions && mentionExamples ?
          <div className="field">
            <label>{I18n.t('components.rich_text.example')}</label>
            <Segment style={{ marginTop: '0em' }} secondary>
              <DynamicRichTextRender renderHtml content={this.props.exampleText}/>
            </Segment>
          </div> : null}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { mentionOptions, mentionExamples } = ownProps.options

  let fullModel = `${ownProps.model}.${ownProps.id}`
  let value = get(state, fullModel)
  let exampleText = value

  if(mentionOptions && mentionExamples && exampleText) {
    mentionOptions.forEach((keyword, index) => {
      exampleText = exampleText.replaceAll(`@mention{${keyword}}`, mentionExamples[index]);
    })
  }

  return {
    value: get(state, fullModel),
    exampleText: exampleText
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {

  let fullModel = `${ownProps.model}.${ownProps.id}`

  return {
    handleChange: (value) => {
      dispatch(formActions.change(fullModel, value))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SemanticRichTextWidget);
